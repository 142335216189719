import React, { useRef } from 'react'
import { useSelector, useDispatch } from 'react-redux'
import useSound from 'use-sound'
import { challenges, textMessageHref, checkSolution } from './game'
import './styles.css'

const StartPage = () => {
  const dispatch = useDispatch()
  const [nextSound] = useSound('next.wav')

  const navToChallenges = {
    type: 'set-page',
    page: 'challenge'
  }

  return (
    <div>
      <h1 className='header'>Happy birthday, Guin!</h1>
      <p className='subheader'>(use on your phone ☎️ &amp; turn your sound on 🔊)</p>
        <button className='button' onClick={() => { nextSound(); dispatch(navToChallenges) }}>
        🎁 Start game 🎁
      </button>
    </div>
  )
}

const ChallengePage = () => {
  const dispatch = useDispatch()
  const challengeIdx = useSelector(s => s.challenge)
  const inputRef = useRef()
  const challenge = challenges[challengeIdx]
  const [winSound] = useSound('success.wav')
  const [nextSound] = useSound('next.wav')
  const [loseSound] = useSound('wahwahwah.wav')

  const onSubmit = inputElt => {
    const solution = inputElt.value.trim()
    inputElt.value = '' // reset input text between renders and after failed attempts

    if (!checkSolution(solution, challenge.solutionHashes)) {
      loseSound()
      alert('Good attempt, but that\'s not it... Try again!')
      return
    }

    dispatch({
      type: 'append-solution',
      solution
    })

    if (challengeIdx < challenges.length-1) {
      nextSound()
      dispatch({ type: 'next-challenge' })
      return
    }

    winSound()
    dispatch({
      type: 'set-page',
      page: 'end'
    })
  }

  return (
    <div>
      <h1 className='header'>Challenge {challengeIdx+1}/{challenges.length}:</h1>
      <img className='image' src={challenge.image} />
      <div className='subheader'>{challenge.subtext}</div>
      <h2 className='question'>{challenge.question}</h2>
      <input className='input' type='text' ref={inputRef} placeholder={challenge.placeholder} />
      <button className='button' onClick={() => onSubmit(inputRef.current)}>😎 Submit answer 🎩</button>
    </div>
  )
}

const EndPage = () => {
  const solutions = useSelector(s => s.solutions)
  const link = textMessageHref(solutions)

  return (
    <div>
      <h1 className='header'>🎺 Congratulations!</h1>
      <img className='image' src='dancing.gif' />
      <a id='endLink' href={link}>Click here to claim your 🎁</a>
    </div>
  )
}

export const App = () => {
  const page = useSelector(s => s.page)

  switch (page) {
    case 'challenge':
      return <ChallengePage />

    case 'end':
      return <EndPage />

    default:
      return <StartPage />
  }
}
