import { sha256 } from 'js-sha256'

// all passwords are lowercased
// then we compare hash(lowercasePasswd) with hashes of solutionHashes
// hashes are hex encoded sha256
export const challenges = [
  {
    image: 'selfie.jpg',
    subtext: 'i need a haircut',
    question: 'What game am I playing?',
    placeholder: 'the game is...',
    solutionHashes: [
      '5d4b9c45bfd610d6bfe764310041479cedf0246ac43d164066c66905dcab9db3',
      '21a355f19d529498dec6a0e8b2e937b856e2b58685661aeb9ef2048c8fc8e48d'
    ], // snippits, snippets
  },
  {
    image: 'box.jpg',
    subtext: 'it\'s valuable',
    question: 'What is this?',
    placeholder: 'you know...',
    solutionHashes: [
      '496e7d0e9eafb14958ecc52aa244031d6191a5cedfca5a09a9095d49714ea8a0',
      '5840ae43beba8949ddaab3a9704cfee19ee03af42d73763e4ba8175a3c593c15',
      'f031dfc3d8b756ee9b504ac83c2b07fa5d697c27dc2e2ba656f76bcc7fc0001d'
    ] // incorderance, incorderence, incorderince
  },
  {
    image: 'door.jpg',
    subtext: 'guin\'s room',
    question: 'What\'s the password?',
    placeholder: 'password',
    solutionHashes: [
      '507b8f98c388371842f7d0b5cd16735df99181cb97d217f8928bc6fd7be7de8a',
      '8e5b57b7b620900e13dd84aae2390dea0eea199aa7f8d34f47b72824276e93f7'
    ], // hello kitty, hellokitty
  },
]

export const textMessageHref = solutions => {
  const phoneNumber = '+13124028493'
  const solutionTxtMsg = `ok i solved your puzzle. my solutions were: ${solutions.join(', ')}.`

  return `sms:${phoneNumber}&body=${encodeURIComponent(solutionTxtMsg)}`
}

export const checkSolution = (inputSolution, solutionHashes) => {
  const hash = sha256.create()
  hash.update(inputSolution.toLowerCase())
  const inputHashHex = hash.hex()

  return solutionHashes.some(sol => sol === inputHashHex)
}
