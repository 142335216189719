import { createStore, applyMiddleware } from 'redux'
import logger from 'redux-logger'

const reducer = (state = {}, action) => {
  switch (action.type) {

    case 'set-page':
      return {
        ...state,
        page: action.page,
      }

    case 'next-challenge':
      return {
        ...state,
        challenge: state.challenge + 1
      }

    case 'append-solution':
      return {
        ...state,
        solutions: [...state.solutions, action.solution],
      }

    default:
      return state

  }
}

const initialState = {
  page: 'start',
  challenge: 0,
  solutions: []
}

export default createStore(
  reducer,
  initialState,
  applyMiddleware(logger)
)
